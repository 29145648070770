export const zIndex = {
	backgroundOverlay: -1,
	listButton: 5,
	facebookChat: 6,
	basketFixed: 7,
	mobileFilterMenu: 8,
	mobileMenu: 9,
	catNavMenu: 10,
	header: 10,
	basketSidebar: 11,
	modals: 12,
}
