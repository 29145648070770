import { createAction, createReducer, createSelector } from '@reduxjs/toolkit'
import { StorefrontCompanyUser } from '../../../types/user/storefrontUser'
import toastMessage from '../components/utils/toasts'
import { API_URL } from '../constants/paths/paths'
import {
	deleteWithUserAuth,
	fetchWithUserAuth,
	postWithUserAuth,
	putWithUserAuth,
} from '../helpers/authHelpers/authHelpers'
import { AsyncActionState } from '../interfaces/global/asyncActionState'
import { AppThunk } from '../store'
import { StorefrontProductList } from '@local-types/productLists/productList'

export const fetchProductListPending = createAction('fetch-product-lists-pending')
export const fetchProductListFulfilled = createAction<StorefrontProductList[]>('fetch-product-lists-fulfilled')
export const addProductToListFulfilled = createAction<StorefrontProductList>('add-product-to-list-fulfilled')
export const createProductListFulfilled = createAction<StorefrontProductList>('create-product-list-fulfilled')
export const setActiveProductList = createAction<StorefrontProductList | null>('set-active-product-list')
export const deleteProductListFulfilled = createAction<string>('delete-product-list-fulfilled')
export const editProductListFulfilled = createAction<string>('edit-product-list-fulfilled')
export const clearProductLists = createAction('clear-product-lists')
export const removeProductFromListFulfilled = createAction<{ listId: string; productId: string }>(
	'remove-product-from-list-fulfilled'
)
export const setActiveProductId = createAction<string>('setActiveProductId')

export const fetchProductLists = (relation: StorefrontCompanyUser | null): AppThunk => async dispatch => {
	try {
		dispatch(fetchProductListPending())
		const { data } = await fetchWithUserAuth(`${API_URL}/product-lists?companyId=${relation?.company.id}`)
		const productLists = data as StorefrontProductList[]
		dispatch(fetchProductListFulfilled(productLists))
	} catch (err) {
		toastMessage({
			type: 'error',
			message: 'Ekki tókst að sækja vörulista',
		})
	}
}

export const addProductToList = (listId: string, productId: string): AppThunk => async dispatch => {
	try {
		const { data } = await postWithUserAuth(`${API_URL}/product-lists/${listId}/products`, { productId })
		dispatch(addProductToListFulfilled(data))
	} catch (error) {
		toastMessage({
			type: 'error',
			message: 'Ekki tókst að setja vöru á lista',
		})
	}
}

export const removeProductFromList = (listId: string, productId: string): AppThunk => async dispatch => {
	try {
		await deleteWithUserAuth(`${API_URL}/product-lists/${listId}/products/${productId}`, {})
		dispatch(removeProductFromListFulfilled({ listId, productId }))
	} catch (error) {
		toastMessage({
			type: 'error',
			message: 'Ekki tókst að fjarlægja vöru af lista',
		})
	}
}

export const deleteProductList = (listId: string): AppThunk => async dispatch => {
	try {
		await deleteWithUserAuth(`${API_URL}/product-lists/${listId}`, {})
		dispatch(deleteProductListFulfilled(listId))
	} catch (error) {
		toastMessage({
			type: 'error',
			message: 'Ekki tókst að fjarlægja vöru af lista',
		})
	}
}

export const editProductList = (listId: string, name: string): AppThunk => async dispatch => {
	try {
		await putWithUserAuth(`${API_URL}/product-lists/${listId}`, { name: name })
		dispatch(editProductListFulfilled(name))
	} catch (error) {
		toastMessage({
			type: 'error',
			message: 'Ekki tókst að breyta lista',
		})
	}
}

export const createProductList = (name: string, companyId: string | undefined): AppThunk => async dispatch => {
	try {
		const { data } = await postWithUserAuth(`${API_URL}/product-lists`, {
			name: name,
			companyId: companyId,
		})
		dispatch(createProductListFulfilled(data))
	} catch (error) {
		toastMessage({
			type: 'error',
			message: 'Ekki tókst að búa til lista',
		})
	}
}

export interface ProductListReducer {
	status: AsyncActionState
	productLists: StorefrontProductList[]
	activeList: StorefrontProductList | null
	activeProductId: string
	byId: {
		[key: string]: StorefrontProductList | null
	}
}

const initialState: ProductListReducer = {
	status: 'passive',
	productLists: [],
	activeList: null,
	activeProductId: '',
	byId: {},
}

const productListReducer = createReducer(initialState, builder =>
	builder
		.addCase(fetchProductListPending, store => {
			store.status = 'pending'
			store.productLists = []
		})
		.addCase(fetchProductListFulfilled, (store, action) => {
			store.status = 'fulfilled'
			store.productLists = action.payload

			action.payload.map(list => {
				store.byId[list.id] = list
			})
		})
		.addCase(addProductToListFulfilled, (store, action) => {
			const index = store.productLists.findIndex(x => x.id === action.payload.id)
			store.productLists[index].products = action.payload.products
		})
		.addCase(setActiveProductId, (store, action) => {
			store.activeProductId = action.payload
		})
		.addCase(removeProductFromListFulfilled, (store, action) => {
			const listIndex = store.productLists.findIndex(list => list.id === action.payload.listId)
			const productListIndex = store.productLists[listIndex].products.findIndex(
				product => product.id === action.payload.productId
			)
			const productList = store.productLists[listIndex]
			productList.products.splice(productListIndex, 1)
			store.productLists[listIndex] = productList
		})
		.addCase(createProductListFulfilled, (store, action) => {
			store.productLists.push(action.payload)
		})
		.addCase(deleteProductListFulfilled, (store, action) => {
			const index = store.productLists.findIndex(x => x.id === action.payload)
			store.productLists.splice(index, 1)
		})
		.addCase(clearProductLists, store => {
			store.productLists = []
		})
		.addCase(setActiveProductList, (store, action) => {
			store.activeList = action.payload
		})
		.addCase(editProductListFulfilled, (store, action) => {
			if (!store.activeList) return
			store.activeList.name = action.payload
			const activeListIndex = store.productLists.findIndex(l => l.id === store.activeList?.id)
			store.productLists[activeListIndex].name = action.payload
		})
)

export default productListReducer
