import styled, { css } from 'styled-components'

interface InputProps {
	hasIcon?: boolean
	hasPrefix?: boolean
}

export const Wrapper = styled.div`
	position: relative;
	display: inline-block;
	border: 1px solid ${({ theme }) => theme.colors.borderColor};
	border-radius: 5px;
	width: 100%;
	transition: border-color 200ms;
	height: 4.8rem;
	font-weight: 700;
	font-size: 1.4rem;
	font-family: ${({ theme }) => theme.fonts.fontFamily};

	&:hover,
	&:focus {
		border-color: ${({ theme }) => theme.colors.primary};
	}

	&:before {
		content: '+354';
		position: absolute;
		top: 50%;
		left: 1.4rem;

		transform: translateY(-50%);
	}
	&:after {
		content: '';
		position: absolute;
		height: 60%;
		width: 1px;
		background-color: ${({ theme }) => theme.colors.borderColor};
		left: 6rem;
		top: 50%;

		transform: translateY(-50%);
	}
`
export const Input = styled.input<InputProps>`
	width: 100%;
	height: 4.8rem;
	padding: 0 2.4rem;
	background: #fff;
	color: ${({ theme }) => theme.colors.textColor};
	font-weight: normal;
	font-size: 1.6rem;
	font-family: ${({ theme }) => theme.fonts.fontFamily};
	line-height: 100%;
	border: 1px solid ${({ theme }) => theme.colors.borderColor};
	border-radius: 5px;
	transition: border-color 200ms;
	position: relative;

	::placeholder {
		color: ${({ theme }) => theme.colors.gray};
	}

	&:hover,
	&:focus {
		border-color: ${({ theme }) => theme.colors.primary};
	}

	&:disabled {
		color: ${({ theme }) => theme.colors.gray};
		background-color: ${({ theme }) => theme.colors.grayLighter};
	}

	${({ hasIcon }) =>
		hasIcon &&
		css`
			padding-right: 4.8rem;
		`}

	${({ hasPrefix }) =>
		hasPrefix &&
		css`
			border: none;
			margin-left: 5rem;

			width: fill-available;
			height: 100%;
		`}
`

interface TextAreaProps {
	resize?: string
}

export const Textarea = styled.textarea<TextAreaProps>`
	width: 100%;
	height: 14.4rem;
	padding: 1.2rem 2.4rem;
	background: #fff;
	color: ${({ theme }) => theme.colors.textColor};
	font-weight: normal;
	font-size: 1.6rem;
	font-family: ${({ theme }) => theme.fonts.fontFamily};
	border: 1px solid ${({ theme }) => theme.colors.borderColor};
	border-radius: 5px;
	transition: border-color 200ms;
	resize: ${({ resize }) => resize || 'both'};

	::placeholder {
		color: ${({ theme }) => theme.colors.gray};
	}

	&:hover,
	&:focus {
		border-color: ${({ theme }) => theme.colors.primary};
	}

	&:disabled {
		color: ${({ theme }) => theme.colors.gray};
		background-color: ${({ theme }) => theme.colors.grayLighter};
	}
`

interface SwitchLabelProps {
	disabled?: boolean
	mt?: string
	mb?: string
	ml?: string
	color?: string
	justifyContent?: string
}

export const SwitchLabel = styled.label<SwitchLabelProps>`
	display: flex;
	align-items: center;
	justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
	margin-bottom: ${({ mb }) => mb || '0'};
	margin-top: ${({ mt }) => mt || '0'};
	color: ${({ theme, color }) => color || theme.colors.textDark};
	transition: opacity 200ms;
	cursor: pointer;

	span:last-child,
	div:last-child,
	strong:last-child {
		display: inline-block;
		font-weight: 400;
		margin-left: ${({ ml }) => ml || '2.5rem'};
	}

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.5;
			pointer-events: none;
		`}
`

export const switchStyles = {
	onColor: '#41D496',
	offColor: '#DEE3EA',
	boxShadow: 'inset 1px 2px 6px rgba(0, 0, 0, 0.12)',
	uncheckedIcon: false,
	checkedIcon: false,
	handleDiameter: 24,
}
